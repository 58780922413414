import React from 'react';
import styled from 'styled-components';
import { WrapXs } from '$components/Wraps';
import Markdown from '$components/Markdown';
import FadeInSection from '$components/FadeInSection';
import { BlockCenteredTextProps } from './types';

const Wrap = styled(WrapXs)`
  margin: 0 auto;

  @media screen and (min-width: 978px) {
    margin: 3.5rem auto;
  }
`;

const BlockCenteredText: React.FC<BlockCenteredTextProps> = ({ content }) => (
  <Wrap>
    <FadeInSection>
      <Markdown content={content} />
    </FadeInSection>
  </Wrap>
);
export default BlockCenteredText;
